import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout'

import { SEO } from "../components/seo"

import ecom from "../images/ecomm-1.jpg"
import ecomFull from "../images/ecomm-2.jpg"

const home = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width-medium center projects">
                 
                  <img src={ecom} alt="Ecomm Jobs" />
                  <h2>Ecomm Jobs </h2>
                  <small class="center">Year: 2021</small>
                  <h3 className='subtitle'>Specialized Ecommerce Jobs Website</h3>
                  <p className='mt20'>An intuitive and easy to use interface design was needed for this specialized job board. The site was planned as a one-pager with sections of additional information loading via javascript.</p>

                  <p> I designed the UI and took care of (S)CSS/HTML. This was 2021, but the site is not live.</p>
                 
              </div>       
              <img className='mt50 project-img' src={ecomFull} alt="Ecomm Jobs" />
             
         </Layout>
    </div>
  )
  }

  export default home

  export const Head = () => (
    <SEO />
  )
  
  